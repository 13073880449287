import React from "react";
import styled from "styled-components";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Header from "../components/Header";

const StyledTitle = styled.h1`
  font-size: 24px;
  color: #111;
  padding-bottom: 16px;
  font-weight: bold;
`;

const AboutPage = () => (
  <>
    <title>Wunder squad</title>
    <Header />
    <Content>
      <StyledTitle>About</StyledTitle>
      <StyledTitle>Wunder squad is a project currently in beta and open only to approved companies.</StyledTitle>
      <h4>Thanks to all the Unsplash photographers:</h4>
      <ul>
        <li><a href="https://unsplash.com/photos/utnV22onlgA">Unsplash</a></li>
        <li><a href="https://unsplash.com/photos/s4nS3munSg8">Unsplash</a></li>
        <li><a href="https://unsplash.com/photos/aZzXKGcyWqk">Unsplash</a></li>
        <li><a href="https://unsplash.com/photos/KgsXAHYWcU8">Unsplash</a></li>
        <li><a href="https://unsplash.com/photos/WnPJft0DJpk">Unsplash</a></li>
        <li><a href="https://unsplash.com/photos/_6cz9KUvnxM">Unsplash</a></li>
        <li><a href="https://unsplash.com/photos/qnt9iigV444">Unsplash</a></li>
        <li><a href="https://unsplash.com/photos/n1m25jvupEU">Unsplash</a></li>
      </ul>
    </Content>
    <Footer />
  </>
)

export default AboutPage;